import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i3 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i5 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import * as i1 from 'ng-zorro-antd/select';
import { NzSelectModule } from 'ng-zorro-antd/select';
function FormlyFieldSelect_ng_container_1_nz_option_group_1_nz_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-option", 6);
  }
  if (rf & 2) {
    const child_r1 = ctx.$implicit;
    i0.ɵɵproperty("nzValue", child_r1.value)("nzDisabled", child_r1.disabled)("nzLabel", child_r1.label);
  }
}
function FormlyFieldSelect_ng_container_1_nz_option_group_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nz-option-group", 4);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_container_1_nz_option_group_1_nz_option_1_Template, 1, 3, "nz-option", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("nzLabel", item_r2.label);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", item_r2.group);
  }
}
function FormlyFieldSelect_ng_container_1_nz_option_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-option", 6);
  }
  if (rf & 2) {
    const item_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("nzValue", item_r2.value)("nzDisabled", item_r2.disabled)("nzLabel", item_r2.label);
  }
}
function FormlyFieldSelect_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_container_1_nz_option_group_1_Template, 2, 2, "nz-option-group", 2)(2, FormlyFieldSelect_ng_container_1_nz_option_2_Template, 1, 3, "nz-option", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r2.group);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !item_r2.group);
  }
}
class FormlyFieldSelect extends FieldType {}
FormlyFieldSelect.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldSelect_BaseFactory;
  return function FormlyFieldSelect_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldSelect_BaseFactory || (ɵFormlyFieldSelect_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldSelect)))(__ngFactoryType__ || FormlyFieldSelect);
  };
})();
FormlyFieldSelect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldSelect,
  selectors: [["formly-field-nz-select"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 12,
  consts: [[3, "ngModelChange", "nzPlaceHolder", "formControl", "formlyAttributes", "nzMode"], [4, "ngFor", "ngForOf"], [3, "nzLabel", 4, "ngIf"], [3, "nzValue", "nzDisabled", "nzLabel", 4, "ngIf"], [3, "nzLabel"], [3, "nzValue", "nzDisabled", "nzLabel", 4, "ngFor", "ngForOf"], [3, "nzValue", "nzDisabled", "nzLabel"]],
  template: function FormlyFieldSelect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "nz-select", 0);
      i0.ɵɵlistener("ngModelChange", function FormlyFieldSelect_Template_nz_select_ngModelChange_0_listener($event) {
        return ctx.props.change && ctx.props.change(ctx.field, $event);
      });
      i0.ɵɵtemplate(1, FormlyFieldSelect_ng_container_1_Template, 3, 2, "ng-container", 1);
      i0.ɵɵpipe(2, "formlySelectOptions");
      i0.ɵɵpipe(3, "async");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassProp("ng-dirty", ctx.showError);
      i0.ɵɵproperty("nzPlaceHolder", ctx.props.placeholder)("formControl", ctx.formControl)("formlyAttributes", ctx.field)("nzMode", ctx.props.multiple ? "multiple" : "default");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(3, 10, i0.ɵɵpipeBind2(2, 7, ctx.props.options, ctx.field)));
    }
  },
  dependencies: [i1.NzSelectComponent, i1.NzOptionGroupComponent, i1.NzOptionComponent, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes, i4.NgForOf, i4.NgIf, i4.AsyncPipe, i5.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldSelect, [{
    type: Component,
    args: [{
      selector: 'formly-field-nz-select',
      template: `
    <nz-select
      [class.ng-dirty]="showError"
      [nzPlaceHolder]="props.placeholder"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [nzMode]="props.multiple ? 'multiple' : 'default'"
      (ngModelChange)="props.change && props.change(field, $event)"
    >
      <ng-container *ngFor="let item of props.options | formlySelectOptions : field | async">
        <nz-option-group *ngIf="item.group" [nzLabel]="item.label">
          <nz-option
            *ngFor="let child of item.group"
            [nzValue]="child.value"
            [nzDisabled]="child.disabled"
            [nzLabel]="child.label"
          >
          </nz-option>
        </nz-option-group>
        <nz-option
          *ngIf="!item.group"
          [nzValue]="item.value"
          [nzDisabled]="item.disabled"
          [nzLabel]="item.label"
        ></nz-option>
      </ng-container>
    </nz-select>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyNzSelectModule {}
FormlyNzSelectModule.ɵfac = function FormlyNzSelectModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyNzSelectModule)();
};
FormlyNzSelectModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyNzSelectModule
});
FormlyNzSelectModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, NzSelectModule, FormlyNzFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'select',
      component: FormlyFieldSelect,
      wrappers: ['form-field']
    }, {
      name: 'enum',
      extends: 'select'
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyNzSelectModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldSelect],
      imports: [CommonModule, ReactiveFormsModule, NzSelectModule, FormlyNzFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'select',
          component: FormlyFieldSelect,
          wrappers: ['form-field']
        }, {
          name: 'enum',
          extends: 'select'
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldSelect, FormlyNzSelectModule };
