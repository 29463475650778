import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import * as i1 from 'ng-zorro-antd/checkbox';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
class FormlyFieldCheckbox extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        indeterminate: true,
        hideLabel: true
      }
    };
  }
}
FormlyFieldCheckbox.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldCheckbox_BaseFactory;
  return function FormlyFieldCheckbox_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldCheckbox_BaseFactory || (ɵFormlyFieldCheckbox_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldCheckbox)))(__ngFactoryType__ || FormlyFieldCheckbox);
  };
})();
FormlyFieldCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldCheckbox,
  selectors: [["formly-field-nz-checkbox"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 4,
  consts: [["nz-checkbox", "", 3, "ngModelChange", "nzIndeterminate", "formControl", "formlyAttributes"]],
  template: function FormlyFieldCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "label", 0);
      i0.ɵɵlistener("ngModelChange", function FormlyFieldCheckbox_Template_label_ngModelChange_0_listener($event) {
        return ctx.props.change && ctx.props.change(ctx.field, $event);
      });
      i0.ɵɵtext(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("nzIndeterminate", ctx.props.indeterminate && ctx.formControl.value == null)("formControl", ctx.formControl)("formlyAttributes", ctx.field);
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate1(" ", ctx.props.label, " ");
    }
  },
  dependencies: [i1.NzCheckboxComponent, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldCheckbox, [{
    type: Component,
    args: [{
      selector: 'formly-field-nz-checkbox',
      template: `
    <label
      nz-checkbox
      [nzIndeterminate]="props.indeterminate && formControl.value == null"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (ngModelChange)="props.change && props.change(field, $event)"
    >
      {{ props.label }}
    </label>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyNzCheckboxModule {}
FormlyNzCheckboxModule.ɵfac = function FormlyNzCheckboxModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyNzCheckboxModule)();
};
FormlyNzCheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyNzCheckboxModule
});
FormlyNzCheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, NzCheckboxModule, FormlyNzFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'checkbox',
      component: FormlyFieldCheckbox,
      wrappers: ['form-field']
    }, {
      name: 'boolean',
      extends: 'checkbox'
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyNzCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldCheckbox],
      imports: [CommonModule, ReactiveFormsModule, NzCheckboxModule, FormlyNzFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'checkbox',
          component: FormlyFieldCheckbox,
          wrappers: ['form-field']
        }, {
          name: 'boolean',
          extends: 'checkbox'
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldCheckbox, FormlyNzCheckboxModule };
