import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { FormlyNzInputModule } from '@ngx-formly/ng-zorro-antd/input';
import { FormlyNzTextAreaModule } from '@ngx-formly/ng-zorro-antd/textarea';
import { FormlyNzRadioModule } from '@ngx-formly/ng-zorro-antd/radio';
import { FormlyNzCheckboxModule } from '@ngx-formly/ng-zorro-antd/checkbox';
import { FormlyNzSelectModule } from '@ngx-formly/ng-zorro-antd/select';
class FormlyNgZorroAntdModule {}
FormlyNgZorroAntdModule.ɵfac = function FormlyNgZorroAntdModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyNgZorroAntdModule)();
};
FormlyNgZorroAntdModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyNgZorroAntdModule
});
FormlyNgZorroAntdModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[FormlyNzFormFieldModule, FormlyNzInputModule, FormlyNzTextAreaModule, FormlyNzRadioModule, FormlyNzCheckboxModule, FormlyNzSelectModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyNgZorroAntdModule, [{
    type: NgModule,
    args: [{
      imports: [FormlyNzFormFieldModule, FormlyNzInputModule, FormlyNzTextAreaModule, FormlyNzRadioModule, FormlyNzCheckboxModule, FormlyNzSelectModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-zorro-antd
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyNgZorroAntdModule };
