import * as i0 from '@angular/core';
import { Component, NgModule } from '@angular/core';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@ngx-formly/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import * as i1 from 'ng-zorro-antd/form';
import { NzFormModule } from 'ng-zorro-antd/form';
import * as i3 from 'ng-zorro-antd/grid';
function FormlyWrapperFormField_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "nz-form-label", 4);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("nzRequired", ctx_r0.props.required && ctx_r0.props.hideRequiredMarker !== true)("nzFor", ctx_r0.id);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.props.label, " ");
  }
}
function FormlyWrapperFormField_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "formly-validation-message", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("field", ctx_r0.field);
  }
}
class FormlyWrapperFormField extends FieldWrapper {
  get errorState() {
    return this.showError ? 'error' : '';
  }
}
FormlyWrapperFormField.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyWrapperFormField_BaseFactory;
  return function FormlyWrapperFormField_Factory(__ngFactoryType__) {
    return (ɵFormlyWrapperFormField_BaseFactory || (ɵFormlyWrapperFormField_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyWrapperFormField)))(__ngFactoryType__ || FormlyWrapperFormField);
  };
})();
FormlyWrapperFormField.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyWrapperFormField,
  selectors: [["formly-wrapper-nz-form-field"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 7,
  vars: 4,
  consts: [["fieldComponent", ""], ["errorTpl", ""], [4, "ngIf"], [3, "nzValidateStatus", "nzErrorTip", "nzExtra"], [3, "nzRequired", "nzFor"], [3, "field"]],
  template: function FormlyWrapperFormField_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "nz-form-item");
      i0.ɵɵtemplate(1, FormlyWrapperFormField_ng_container_1_Template, 3, 3, "ng-container", 2);
      i0.ɵɵelementStart(2, "nz-form-control", 3);
      i0.ɵɵelementContainer(3, null, 0);
      i0.ɵɵtemplate(5, FormlyWrapperFormField_ng_template_5_Template, 1, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      const errorTpl_r2 = i0.ɵɵreference(6);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.label && ctx.props.hideLabel !== true);
      i0.ɵɵadvance();
      i0.ɵɵproperty("nzValidateStatus", ctx.errorState)("nzErrorTip", errorTpl_r2)("nzExtra", ctx.props.description);
    }
  },
  dependencies: [i1.NzFormItemComponent, i1.NzFormLabelComponent, i1.NzFormControlComponent, i2.ɵFormlyValidationMessage, i3.NzRowDirective, i4.NgIf, i3.NzColDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyWrapperFormField, [{
    type: Component,
    args: [{
      selector: 'formly-wrapper-nz-form-field',
      template: `
    <nz-form-item>
      <ng-container *ngIf="props.label && props.hideLabel !== true">
        <nz-form-label [nzRequired]="props.required && props.hideRequiredMarker !== true" [nzFor]="id">
          {{ props.label }}
        </nz-form-label>
      </ng-container>
      <nz-form-control [nzValidateStatus]="errorState" [nzErrorTip]="errorTpl" [nzExtra]="props.description">
        <ng-container #fieldComponent></ng-container>
        <ng-template #errorTpl let-control>
          <formly-validation-message [field]="field"></formly-validation-message>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `
    }]
  }], null, null);
})();
class FormlyNzFormFieldModule {}
FormlyNzFormFieldModule.ɵfac = function FormlyNzFormFieldModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyNzFormFieldModule)();
};
FormlyNzFormFieldModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyNzFormFieldModule
});
FormlyNzFormFieldModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, NzFormModule, FormlyModule.forChild({
    wrappers: [{
      name: 'form-field',
      component: FormlyWrapperFormField
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyNzFormFieldModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyWrapperFormField],
      imports: [CommonModule, ReactiveFormsModule, NzFormModule, FormlyModule.forChild({
        wrappers: [{
          name: 'form-field',
          component: FormlyWrapperFormField
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyNzFormFieldModule };
