import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i4 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import * as i3 from 'ng-zorro-antd/input';
import { NzInputModule } from 'ng-zorro-antd/input';
import * as i1 from 'ng-zorro-antd/input-number';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
function FormlyFieldInput_input_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.formControl)("type", ctx_r0.props.type || "text")("formlyAttributes", ctx_r0.field);
  }
}
function FormlyFieldInput_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-input-number", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.formControl)("formlyAttributes", ctx_r0.field);
  }
}
class FormlyFieldInput extends FieldType {}
FormlyFieldInput.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldInput_BaseFactory;
  return function FormlyFieldInput_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldInput_BaseFactory || (ɵFormlyFieldInput_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldInput)))(__ngFactoryType__ || FormlyFieldInput);
  };
})();
FormlyFieldInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldInput,
  selectors: [["formly-field-nz-input"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 2,
  consts: [["numberTmp", ""], ["nz-input", "", 3, "formControl", "type", "formlyAttributes", 4, "ngIf", "ngIfElse"], ["nz-input", "", 3, "formControl", "type", "formlyAttributes"], [3, "formControl", "formlyAttributes"]],
  template: function FormlyFieldInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldInput_input_0_Template, 1, 3, "input", 1)(1, FormlyFieldInput_ng_template_1_Template, 1, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const numberTmp_r2 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", ctx.props.type !== "number")("ngIfElse", numberTmp_r2);
    }
  },
  dependencies: [i1.NzInputNumberComponent, i2.NgIf, i3.NzInputDirective, i4.DefaultValueAccessor, i4.NgControlStatus, i4.FormControlDirective, i5.ɵFormlyAttributes],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldInput, [{
    type: Component,
    args: [{
      selector: 'formly-field-nz-input',
      template: `
    <input
      *ngIf="props.type !== 'number'; else numberTmp"
      nz-input
      [formControl]="formControl"
      [type]="props.type || 'text'"
      [formlyAttributes]="field"
    />
    <ng-template #numberTmp>
      <nz-input-number [formControl]="formControl" [formlyAttributes]="field"></nz-input-number>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyNzInputModule {}
FormlyNzInputModule.ɵfac = function FormlyNzInputModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyNzInputModule)();
};
FormlyNzInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyNzInputModule
});
FormlyNzInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, NzInputModule, NzInputNumberModule, FormlyNzFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'input',
      component: FormlyFieldInput,
      wrappers: ['form-field']
    }, {
      name: 'string',
      extends: 'input'
    }, {
      name: 'number',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }, {
      name: 'integer',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyNzInputModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldInput],
      imports: [CommonModule, ReactiveFormsModule, NzInputModule, NzInputNumberModule, FormlyNzFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'input',
          component: FormlyFieldInput,
          wrappers: ['form-field']
        }, {
          name: 'string',
          extends: 'input'
        }, {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }, {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldInput, FormlyNzInputModule };
