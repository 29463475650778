import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i4 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import * as i1 from 'ng-zorro-antd/radio';
import { NzRadioModule } from 'ng-zorro-antd/radio';
function FormlyFieldRadio_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r1 = ctx.$implicit;
    i0.ɵɵproperty("nzValue", option_r1.value)("nzDisabled", option_r1.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r1.label, " ");
  }
}
class FormlyFieldRadio extends FieldType {}
FormlyFieldRadio.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldRadio_BaseFactory;
  return function FormlyFieldRadio_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldRadio_BaseFactory || (ɵFormlyFieldRadio_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldRadio)))(__ngFactoryType__ || FormlyFieldRadio);
  };
})();
FormlyFieldRadio.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldRadio,
  selectors: [["formly-field-nz-radio"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 7,
  consts: [[3, "ngModelChange", "formControl"], ["nz-radio", "", 3, "nzValue", "nzDisabled", 4, "ngFor", "ngForOf"], ["nz-radio", "", 3, "nzValue", "nzDisabled"]],
  template: function FormlyFieldRadio_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "nz-radio-group", 0);
      i0.ɵɵlistener("ngModelChange", function FormlyFieldRadio_Template_nz_radio_group_ngModelChange_0_listener($event) {
        return ctx.props.change && ctx.props.change(ctx.field, $event);
      });
      i0.ɵɵtemplate(1, FormlyFieldRadio_label_1_Template, 2, 3, "label", 1);
      i0.ɵɵpipe(2, "formlySelectOptions");
      i0.ɵɵpipe(3, "async");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("formControl", ctx.formControl);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(3, 5, i0.ɵɵpipeBind2(2, 2, ctx.props.options, ctx.field)));
    }
  },
  dependencies: [i1.NzRadioGroupComponent, i1.NzRadioComponent, i2.NgControlStatus, i2.FormControlDirective, i3.NgForOf, i3.AsyncPipe, i4.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldRadio, [{
    type: Component,
    args: [{
      selector: 'formly-field-nz-radio',
      template: `
    <nz-radio-group [formControl]="formControl" (ngModelChange)="props.change && props.change(field, $event)">
      <label
        nz-radio
        *ngFor="let option of props.options | formlySelectOptions : field | async"
        [nzValue]="option.value"
        [nzDisabled]="option.disabled"
      >
        {{ option.label }}
      </label>
    </nz-radio-group>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyNzRadioModule {}
FormlyNzRadioModule.ɵfac = function FormlyNzRadioModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyNzRadioModule)();
};
FormlyNzRadioModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyNzRadioModule
});
FormlyNzRadioModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, NzRadioModule, FormlyNzFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'radio',
      component: FormlyFieldRadio,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyNzRadioModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldRadio],
      imports: [CommonModule, ReactiveFormsModule, NzRadioModule, FormlyNzFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'radio',
          component: FormlyFieldRadio,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldRadio, FormlyNzRadioModule };
