import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import * as i1 from 'ng-zorro-antd/input';
import { NzInputModule } from 'ng-zorro-antd/input';
class FormlyFieldTextArea extends FieldType {}
FormlyFieldTextArea.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldTextArea_BaseFactory;
  return function FormlyFieldTextArea_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldTextArea_BaseFactory || (ɵFormlyFieldTextArea_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldTextArea)))(__ngFactoryType__ || FormlyFieldTextArea);
  };
})();
FormlyFieldTextArea.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldTextArea,
  selectors: [["formly-field-nz-textarea"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 2,
  consts: [["nz-input", "", 3, "formControl", "formlyAttributes"]],
  template: function FormlyFieldTextArea_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "textarea", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("formControl", ctx.formControl)("formlyAttributes", ctx.field);
    }
  },
  dependencies: [i1.NzInputDirective, i2.DefaultValueAccessor, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldTextArea, [{
    type: Component,
    args: [{
      selector: 'formly-field-nz-textarea',
      template: ` <textarea nz-input [formControl]="formControl" [formlyAttributes]="field"></textarea> `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyNzTextAreaModule {}
FormlyNzTextAreaModule.ɵfac = function FormlyNzTextAreaModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyNzTextAreaModule)();
};
FormlyNzTextAreaModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyNzTextAreaModule
});
FormlyNzTextAreaModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, NzInputModule, FormlyNzFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'textarea',
      component: FormlyFieldTextArea,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyNzTextAreaModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldTextArea],
      imports: [CommonModule, ReactiveFormsModule, NzInputModule, FormlyNzFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'textarea',
          component: FormlyFieldTextArea,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldTextArea, FormlyNzTextAreaModule };
